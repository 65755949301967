/*****************************************************
 * node_modules imports
 *****************************************************/
@import 'node_modules/bootstrap/scss/bootstrap.scss';

/*****************************************************
 * project imports
 *****************************************************/
@import 'variables';
@import 'mixins';

/*****************************************************
 * styles
 *****************************************************/
html, body, app-root, app-game {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: $mainBgColor;
  color: $mainTextColor;  
}

nav{
  width:300px;
  height:auto;
  background:#ffffff38;
  align-self:center;
  justify-self:center;
  border-radius:10px;
  position:fixed;
  bottom: 10px;
  left: 10px;
  padding:10px;
}
.logo{
  width:95px;
  position:absolute;
  inset:15px;
  filter:grayscale(100%);
  -webkit-filter:grayscale(100%);
}
hr, .hr1{
  width:100%;
  height:1px;
  border:none;
  border-bottom:1px solid rgba(111, 111, 111, 0.60);
  margin-top:43px;
}
.pages{
  display:block;
  font-size:14px;
  box-sizing:border-box;
  cursor:pointer;
  user-select:none;
  padding:0px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin:0px;
}
.pages svg{
  height:21px;
  margin-right: 10px;
}
.pages svg path{
  fill:lightgray;
}

.accordion-item { background: transparent; border: none;}
.accordion-button{ background: transparent; color: #ffffff; border: none; padding: 8px;}
.accordion-button:not(.collapsed){ box-shadow: none; background: #00000042;color: #ffffff;}
.home-p svg path{
  fill:white;
}
.home-p, .pages:hover{
  color:white;
  background:rgba(000, 000, 000, 0.30);
}
.pages:hover svg path{
  fill:white;
}
.hr2{
  margin-top: 6px;
}
.list{
  width:100%;
  padding:5px 0px;
  max-height:200px;
  overflow: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.2588235294);
  &::-webkit-scrollbar{
    width:7px;
  }
}
.list div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:100%;
  color:lightgray;
  cursor: pointer;
  min-height: 20px;
  margin: 7px;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.list div img{
  width:90px;
  filter:grayscale(60%);
  -webkit-filter:grayscale(60%);
}
.list div:hover img{
  filter:grayscale(0%);
  -webkit-filter:grayscale(0%);
}
.list div p{
  font-size: 12px;
  font-weight: bolder;
  margin-bottom: 0px;
}
.list div a{
  font-size: 9px;
  margin-top: 3px;
}
.list div:hover{
  color:white;
}
.hr3{
  margin-top: 10px;
}
nav .accordion{
  padding:10px;
}
.play{
  max-width: 100%;
  background: #fff;
  color: #000;
  padding: 20px 10px 10px;
  border-radius:15px;
}
.play .controls svg{
   width:37px;
   opacity: 0.8;
   margin-top:6px;
}
.play_current{
    margin-bottom: 10px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px;
}
.songname{
  margin-right:10px;
}
.songname p{
    margin-bottom:-0px!important;
    font-size:18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    &.active{color:#1ed760;}
}
.songname span{
    font-size:12px;
    display:inline-block;
    margin-top: -2px;
}
.favIc{
    width:20px;
    height:20px;
    cursor: pointer;
    &.active{
      fill:red;
    }
}
.play img{
    width: 55px;
    margin-right: 5px;
}
.play .container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.play .progress{
    width:110px;
    height:6px;
}
.play .progress-bar{
  background:green;
}
.play .songName{
  font-weight: bolder;
  font-size: 12px;
  margin-top: 0px;
}
.play .name{
  font-size: 9px;
  margin-top: -10px;
}
.play .container div{
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
}
.play .container div span{
  width: 100%;
  height: 2px;
  position: relative;
  display: block;
  background-color: rgba(111, 111, 111, 0.90);
  margin: 0px 5px;
  display: flex;
  align-items: center;
}
.play .container div span::after{
  position: absolute;
  content: "";
  width:60%;
  height: 100%;
  background-color: #1ed760;
}
.play .container div span::before{
  width:10px;
  height: 10px;
  position: absolute;
  content: "";
  background-color: #1ed760;
  opacity: 0;
  left: 60%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.play:hover .container div span::before{
  opacity: 1;
}
.play .container div p{
  font-size: 8px;
}
.play .container .controls{
  display: flex;
  align-items: center;
  margin-top: -2px;
}
.play .container .controls svg{
  fill:lightgray;
  border-radius: 50%;
  width: 14px;
  margin: 0px 5px;
  height: 14px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
}
.play .container .controls svg.play-btn{
  background-color: lightgray;
  fill:black;
  padding: 2px;
}
.play .container .controls svg:hover{
  fill: #1ed760;
}
.play .container .controls svg.play-btn:hover{
  background-color: #1ed760;
  fill:lightgray;
}
::-webkit-scrollbar{
  height: 6px;
}
::-webkit-scrollbar-track{
  background: transparent;
}
::-webkit-scrollbar-thumb{
  background-color: rgba(111, 111, 111, 0.60);
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}
::-webkit-scrollbar-thumb:hover{
  background-color: rgba(111, 111, 111, 0.80);
}

.engine-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  #renderCanvas {
    width: 100% !important;
    height: 100% !important;
    touch-action: none;
  }

  #renderCanvas:focus {
    outline: none;
  }
  #rendererstarCanvas {
    width: 100% !important;
    height: 100% !important;
    touch-action: none;
  }

  #rendererstarCanvas:focus {
    outline: none;
  }
}

.ui-wrapper {
  position: absolute;

  * {
    z-index: 10;
  }
}
canvas{
  width: 100px !important;
  height: 100px !important;
}
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
/* Add some content at the bottom of the video/page */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
/* Style the button used to pause/play the video */
#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}
#myBtn:hover {
  background: #ddd;
  color: black;
} 

.bgVideo{
  height:100vh;width:100%;
  background-image: url('../assets/globe/2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
  top:0;left:0;right:0;bottom:0;
}
.globe{
  position: fixed;
  width: 100%;
  top:50%;
  transform: translateY(-50%);
  height:100vh;
  display:flex;
  align-items: center;
  justify-content: center;
}
.go{
  position: absolute;
  height: inherit;
  width: inherit;
 // background: url("https://maprepublic.com/wp-content/uploads/2017/05/kanawa-world-map-large.jpg");
 // background:url("../assets/img/world.topo.bathy.200402.3x5400x2700.png");
  border-radius: 50%;
  box-shadow: inset 25px 0px 35px 3px black;
  background-size: 350px;
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform: rotate(-10deg);
  width:400px;height:400px;
}

@keyframes rotate {
  from{ background-position: 0px;}
  to{ background-position: 350px;}
}
.ngx-slider .ngx-slider-pointer{
  width:22px!important;height:22px!important;
  top:-10px!important;
  background: #38383d!important;
}
.ngx-slider .ngx-slider-pointer:after{
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
.ngx-slider .ngx-slider-selection{
  background: #38383d!important;
}
.pages #button-addon2{
  background: #000;
  color: #fff;
  border-color: #000;
}
.form-control{
  box-shadow:none!important;
}

@media  screen and (max-width:767px){
  .globleRotate{img{width:100%!important;}}
  .go{width:350px;height:350px;}
}